import { Moment } from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNeedsAssessment } from './order-needs-assessment.interface';

export type FormModel = Pick<
  IOrderNeedsAssessment,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'assessmentType'
  | 'assessmentCentreId'
  | 'isReviewRequested'
  | 'shareNarWithHep'
  | 'quoteId'
  | 'qualityAssuranceUserId'
  | 'organisationId'
> & {
  acceptanceLetterFile: File | null;
  nextContactDate: Moment | null;
  reportSentDate: Moment | null;
  submissionDate: Moment | null;
};

export type ApiModel = Pick<
  IOrderNeedsAssessment,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'assessmentType'
  | 'assessmentCentreId'
  | 'isReviewRequested'
  | 'shareNarWithHep'
  | 'quoteId'
  | 'nextContactDate'
  | 'reportSentDate'
  | 'qualityAssuranceUserId'
  | 'submissionDate'
  | 'organisationId'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  assessmentType: { type: 'backend-label', optionKey: 'assessmentTypeOptions', initial: null },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', initial: null },
  isReviewRequested: { type: 'transportable', initial: false },
  shareNarWithHep: { type: 'transportable', initial: false },
  quoteId: { type: 'transportable', initial: '' },
  nextContactDate: { type: 'date', initial: null },
  reportSentDate: { type: 'date', initial: null },
  qualityAssuranceUserId: { type: 'backend-label', optionKey: 'userOptions', initial: null },
  submissionDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  labels: Serializers.array,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
  assessmentType: Serializers.backendValue,
  assessmentCentreId: Serializers.backendValue,
  isReviewRequested: Serializers.boolean,
  shareNarWithHep: Serializers.boolean,
  quoteId: Serializers.string,
  nextContactDate: Serializers.date,
  reportSentDate: Serializers.dateTime,
  qualityAssuranceUserId: Serializers.backendValue,
  submissionDate: Serializers.dateTime,
  organisationId: Serializers.backendValue,
});
