import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderAnnualSupportCharge } from './annual-support-charge-order.interface';

export type FormModel = Pick<
  IOrderAnnualSupportCharge,
  'orderStatusId' | 'userId' | 'labels' | 'quoteInternalId' | 'notes'
> & {
  acceptanceLetterFile: File | null;
};

export type ApiModel = Pick<
  IOrderAnnualSupportCharge,
  'orderStatusId' | 'userId' | 'labels' | 'quoteInternalId' | 'notes'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  notes: { type: 'transportable', initial: '' },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  quoteInternalId: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  notes: Serializers.string,
  labels: Serializers.array,
  acceptanceLetterFile: Serializers.file,
  quoteInternalId: Serializers.string,
});
