import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNew } from './order-new.interface';

export type FormModel = Pick<IOrderNew, 'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export type ApiModel = Pick<IOrderNew, 'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', initial: null },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: Serializers.backendValue,
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  labels: Serializers.backendValue,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
});
