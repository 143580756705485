import { InstallationOptions } from '@features/order/shared/installation.type';
import { SystemOptions } from '@features/order/shared/system.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { ApiModel as TechnicalApiModel, FormModel as TechnicalFormModel } from '../technical/form-config';
import { RepairAndReplaceType, RepairAndReplaceTypeOptions } from './repair-and-replace-type.type';

export type FormModel = Omit<TechnicalFormModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

export type ApiModel = Omit<TechnicalApiModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

// TODO copy-pasted and extended from tech order for now
export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  isContributePayable: { type: 'transportable', initial: false },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  warranty: { type: 'backend-label', optionKey: 'warrantyOptions', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  paymentLink: { type: 'transportable', initial: '' },
  salesId: { type: 'transportable', initial: '' },
  pickUp: { type: 'date', initial: null },
  arranged: { type: 'date', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
  rnrQuotationFile: { type: 'transportable', initial: null },
  isUpgradedEquipment: { type: 'transportable', initial: false },
  dsa2ApprovedAmount: { type: 'transportable', initial: '' },
  upgradePrice: { type: 'transportable', initial: '' },
  system: { type: 'enum', options: SystemOptions, initial: null },
  installationType: { type: 'enum', options: InstallationOptions, initial: null },
  immyBotInstallationLink: { type: 'transportable', initial: '' },
  repairAndReplaceType: { type: 'enum', options: RepairAndReplaceTypeOptions, initial: null },
});

// TODO copy-pasted and extended from tech order for now
export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  labels: Serializers.backendValue,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
  isContributePayable: Serializers.boolean,
  organisationId: Serializers.backendValue,
  warranty: Serializers.backendValue,
  quoteId: Serializers.string,
  paymentLink: Serializers.string,
  salesId: Serializers.string,
  pickUp: Serializers.date,
  arranged: Serializers.date,
  invoiceNumber: Serializers.string,
  rnrQuotationFile: Serializers.file,
  isUpgradedEquipment: Serializers.boolean,
  dsa2ApprovedAmount: Serializers.money,
  upgradePrice: Serializers.money,
  system: Serializers.enum,
  installationType: Serializers.enum,
  immyBotInstallationLink: Serializers.string,
  repairAndReplaceType: Serializers.enum,
});
