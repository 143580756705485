// This file is autogenerated during deploy.sh
// Please do not change it manually

// This file is used in both webpack and the application.

// We need a CommonJS module for use with webpack
// So here, we use a JS file with TS typings
module.exports = {
  baseUrl: 'https://sandbox2-api.assistivedsa.com',
  title: 'Sandbox2 Assistive DSA CRM',
  color: '#c896de',
  isWarning: true,
};
