import { Moment } from 'moment-timezone';
import { SystemOptions } from '@features/order/shared/system.type';
import { InstallationOptions } from '@features/order/shared/installation.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderTechnical } from './order-technical.interface';

export type FormModel = Pick<
  IOrderTechnical,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'isContributePayable'
  | 'organisationId'
  | 'warranty'
  | 'quoteId'
  | 'paymentLink'
  | 'salesId'
  | 'invoiceNumber'
  | 'isUpgradedEquipment'
  | 'dsa2ApprovedAmount'
  | 'upgradePrice'
  | 'system'
  | 'installationType'
  | 'immyBotInstallationLink'
> & {
  acceptanceLetterFile: File | null;
  pickUp: Moment | null;
  arranged: Moment | null;
  quotationFile: File | null;
};

export type ApiModel = Pick<
  IOrderTechnical,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'isContributePayable'
  | 'organisationId'
  | 'warranty'
  | 'quoteId'
  | 'paymentLink'
  | 'salesId'
  | 'pickUp'
  | 'arranged'
  | 'invoiceNumber'
  | 'isUpgradedEquipment'
  | 'dsa2ApprovedAmount'
  | 'upgradePrice'
  | 'system'
  | 'installationType'
  | 'immyBotInstallationLink'
> & {
  acceptanceLetterFile: File | null;
  quotationFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  isContributePayable: { type: 'transportable', initial: false },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  warranty: { type: 'backend-label', optionKey: 'warrantyOptions', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  paymentLink: { type: 'transportable', initial: '' },
  salesId: { type: 'transportable', initial: '' },
  pickUp: { type: 'date', initial: null },
  arranged: { type: 'date', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
  quotationFile: { type: 'transportable', initial: null },
  isUpgradedEquipment: { type: 'transportable', initial: false },
  dsa2ApprovedAmount: { type: 'transportable', initial: '' },
  upgradePrice: { type: 'transportable', initial: '' },
  system: { type: 'enum', options: SystemOptions, initial: null },
  installationType: { type: 'enum', options: InstallationOptions, initial: null },
  immyBotInstallationLink: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  labels: Serializers.backendValue,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
  isContributePayable: Serializers.boolean,
  organisationId: Serializers.backendValue,
  warranty: Serializers.backendValue,
  quoteId: Serializers.string,
  paymentLink: Serializers.string,
  salesId: Serializers.string,
  pickUp: Serializers.date,
  arranged: Serializers.date,
  invoiceNumber: Serializers.string,
  quotationFile: Serializers.file,
  isUpgradedEquipment: Serializers.boolean,
  dsa2ApprovedAmount: Serializers.money,
  upgradePrice: Serializers.money,
  system: Serializers.enum,
  installationType: Serializers.enum,
  immyBotInstallationLink: Serializers.string,
});
