import moment, { Moment } from 'moment-timezone';
import { intersection } from 'lodash';
import { AppBackendLabels, IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { isISODateString, isISODateTimeString, isISODateWithoutTimeString } from '@utils/dates/iso-string.type';
import { Transportable, TransportableModel } from '@models/transportable.type';
import { NullOption } from '../null.option';
import { LondonTimezone } from '../dates/london-timezone';

type BackendLabelOptionBase = {
  type: 'backend-label';
  optionKey: keyof AppBackendLabels;
};

type BackendLabelOptionSingle = BackendLabelOptionBase & {
  multiple?: boolean;
  initial: null;
};

type BackendLabelOptionMultiple = BackendLabelOptionBase & {
  multiple: true;
  initial: [];
};

type BackendLabelOption = BackendLabelOptionSingle | BackendLabelOptionMultiple;

type DateOption = {
  type: 'date';
  initial: Moment | null;
};

type DateTimeOption = {
  type: 'date-time';
  initial: Moment | null;
};

type EnumOptionBase = {
  type: 'enum';
  options: IBackendLabelOption<string | number | null>[];
};

type EnumOptionSingle = EnumOptionBase & {
  multiple?: boolean;
  initial: null;
};

type EnumOptionMultiple = EnumOptionBase & {
  multiple: true;
  initial: [];
};

type EnumOption = EnumOptionSingle | EnumOptionMultiple;

type TransportableOption = {
  type: 'transportable';
  initial: Transportable;
  isEmptyStringAllowed?: boolean;
};

type SanitizeOption = BackendLabelOption | DateOption | DateTimeOption | EnumOption | TransportableOption;

type SanitizeMap<FormModel> = {
  [K in keyof FormModel]: SanitizeOption;
};

/**
 * Helper factory accepting `ApiModel` and `FormModel` type template and sanitize map, describing declarative validation.
 * `ApiModel` type is a model being sanitized, a plain serialized object.
 * `FormModel` is result model for the frontend usage
 * @returns function accepting some `TransportableModel` model and backend label options, since they are exist in runtime
 * and returning `FormModel` model with only valid values
 */
export function sanitizeFactory<ApiModel extends TransportableModel<ApiModel>, FormModel>(
  sanitizeMap: SanitizeMap<FormModel>,
) {
  return (model: Partial<ApiModel> | void | null, backendLabels: AppBackendLabels): FormModel => {
    const result: Partial<FormModel> = {};

    for (const key in sanitizeMap) {
      const option = sanitizeMap[key];

      if (!option) {
        continue;
      }

      if (!model || !(key in model)) {
        result[key] = ensureType<FormModel[typeof key]>(option.initial);
        continue;
      }

      const value = model[key];

      switch (option.type) {
        case 'backend-label':
          if (option.optionKey) {
            const availableIds = [NullOption, ...backendLabels[option.optionKey]].map(({ value }) => value);
            if (option.multiple) {
              const ids = getArrayValue(value);
              result[key] = ensureType<FormModel[typeof key]>(intersection(availableIds, ids));
            } else if (!option.multiple && availableIds.includes(value as string | number | null)) {
              result[key] = value;
            } else {
              result[key] = ensureType<FormModel[typeof key]>(option.initial);
            }
          }
          break;

        case 'date':
          if (isISODateString(value)) {
            result[key] = ensureType<FormModel[typeof key]>(
              isISODateWithoutTimeString(value) ? moment(value) : moment(value).tz(LondonTimezone),
            );
          } else {
            result[key] = ensureType<FormModel[typeof key]>(option.initial);
          }

          break;

        case 'date-time':
          result[key] = ensureType<FormModel[typeof key]>(
            isISODateTimeString(value) ? moment(value).tz(LondonTimezone) : option.initial,
          );
          break;

        case 'enum': {
          const validValues = [NullOption, ...option.options].map(({ value }) => value);
          if (option.multiple) {
            const ids = getArrayValue(value);
            result[key] = ensureType<FormModel[typeof key]>(intersection(validValues, ids));
          } else if (!option.multiple && validValues.includes(value as string | number | null)) {
            result[key] = value;
          } else {
            result[key] = ensureType<FormModel[typeof key]>(option.initial);
          }
          break;
        }
        case 'transportable':
          if (['string', 'number', 'boolean'].includes(typeof value)) {
            if (option.isEmptyStringAllowed || value !== '') {
              result[key] = value;
            }
          } else if (value instanceof File || value instanceof FileList) {
            result[key] = ensureType<FormModel[typeof key]>(value);
          } else {
            result[key] = ensureType<FormModel[typeof key]>(option.initial);
          }
          break;
      }
    }

    return result as FormModel;
  };
}

function getArrayValue(value: unknown): unknown[] {
  if (Array.isArray(value)) {
    return value;
  }
  if (value === null) {
    return [];
  }
  return [value];
}

// utility to assert type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ensureType<T>(value: any): T {
  return value as T;
}
