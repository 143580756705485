import { Moment } from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNmh } from './order-nmh.interface';

export type FormModel = Pick<
  IOrderNmh,
  'orderStatusId' | 'userId' | 'labels' | 'notes' | 'levelOfStudy' | 'intensity' | 'organisationId'
> & {
  acceptanceLetterFile: File | null;
  courseEndDate: Moment | null;
  equipmentDeliveryDate: Moment | null;
};

export type ApiModel = Pick<
  IOrderNmh,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'levelOfStudy'
  | 'intensity'
  | 'courseEndDate'
  | 'organisationId'
  | 'equipmentDeliveryDate'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  levelOfStudy: { type: 'backend-label', optionKey: 'levelOfStudyOptions', initial: null },
  intensity: { type: 'backend-label', optionKey: 'intensityOptions', initial: null },
  courseEndDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  equipmentDeliveryDate: { type: 'date', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  labels: Serializers.backendValue,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
  levelOfStudy: Serializers.backendValue,
  intensity: Serializers.backendValue,
  courseEndDate: Serializers.date,
  organisationId: Serializers.backendValue,
  equipmentDeliveryDate: Serializers.date,
});
